document.documentElement.classList.add('cc--darkmode');

CookieConsent.run({
    guiOptions: {
        consentModal: {
            layout: "box",
            position: "bottom left",
            equalWeightButtons: true,
            flipButtons: false
        },
        preferencesModal: {
            layout: "box",
            position: "right",
            equalWeightButtons: true,
            flipButtons: false
        }
    },
    categories: {
        necessary: {
            readOnly: true
        },
        analytics: {}
    },
    language: {
        default: "de", // Set default language to German
        autoDetect: "browser",
        translations: {
            de: {
                consentModal: {
                    title: "Hallo, Reisender! Es ist Zeit für Cookies!",
                    description: "Wir verwenden Cookies, um sicherzustellen, dass du die bestmögliche Erfahrung auf unserer Website machst. Indem du weiterhin auf unserer Website surfst, stimmst du unserer Verwendung von Cookies zu.",
                    acceptAllBtn: "Alle akzeptieren",
                    acceptNecessaryBtn: "Nur notwendige Cookies",
                    showPreferencesBtn: "Einstellungen verwalten",
                    footer: "<a href=\"#privacy\">Datenschutz-Bestimmungen</a>"
                },
                preferencesModal: {
                    title: "Cookie-Einstellungen",
                    acceptAllBtn: "Alle akzeptieren",
                    acceptNecessaryBtn: "Nur notwendige Cookies",
                    savePreferencesBtn: "Einstellungen speichern",
                    closeIconLabel: "Schließen",
                    serviceCounterLabel: "Dienst|Dienste",
                    sections: [
                        {
                            title: "Cookie-Nutzung",
                            description: "Hier kannst du deine Cookie-Einstellungen verwalten und mehr über unsere Verwendung von Cookies erfahren."
                        },
                        {
                            title: "Unbedingt notwendige Cookies <span class=\"pm__badge\">Immer aktiviert</span>",
                            description: "Diese Cookies sind für das Funktionieren der Website erforderlich und können in unseren Systemen nicht deaktiviert werden. Sie werden in der Regel nur als Reaktion auf deine Aktionen festgelegt, die zu einer Anfrage nach Diensten führen, wie z.B. das Festlegen deiner Datenschutzeinstellungen, das Anmelden oder das Ausfüllen von Formularen."
                        },
                        {
                            title: "Analytische Cookies",
                            description: "Diese Cookies ermöglichen es uns, Besuche und Traffic-Quellen zu zählen, um die Leistung unserer Website zu messen und zu verbessern. Sie helfen uns zu verstehen, welche Seiten am beliebtesten und am wenigsten beliebt sind und wie sich Besucher auf der Website bewegen."
                        },
                        {
                            title: "Mehr Informationen",
                            description: "Für weitere Informationen zu unserer Cookie-Richtlinie und Ihren Wahlmöglichkeiten kontaktiere uns bitte über <a class=\"cc__link\" href=\"#yourdomain.com\">Kontakt</a>."
                        }
                    ]
                }
            }
        }
    }
});
